.competitor-mentions-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    gap: 15px;
}

.product-selector {
    min-width: 200px;
}

.chart-container {
    margin-top: 20px;
    position: relative;
}

/* Chart and legend layout */
.chart-with-legend {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    margin-bottom: 15px;
}

.pie-chart-container {
    position: relative;
    width: 400px;
    height: 400px;
}

/* Center label for chart */
.chart-center-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    pointer-events: none;
    z-index: 1;
}

.total-mentions {
    font-size: 28px;
    font-weight: 700;
    color: #333;
}

.total-mentions-label {
    font-size: 14px;
    color: #666;
    margin-top: 4px;
}

/* Time range description */
.time-range-description {
    text-align: center;
    color: #666;
    font-size: 14px;
    margin-top: 10px;
    font-style: italic;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
}

.loading-text {
    margin-top: 20px;
    font-size: 16px;
    color: #666;
}

.error-message {
    padding: 20px;
    background-color: #fff8f8;
    border-left: 4px solid #ff6b6b;
    color: #d32f2f;
    margin: 20px 0;
    border-radius: 4px;
    font-size: 16px;
}

.no-data-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    color: #666;
    font-size: 16px;
    text-align: center;
    /* background-color: #f9f9f9; */
    border-radius: 8px;
    margin-top: 20px;
}

/* Custom tooltip styles */
.custom-tooltip {
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 8px;
    padding: 12px 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    border: 1px solid #eaeaea;
    z-index: 10;
}

.competitor-name {
    font-weight: 600;
    font-size: 16px;
    margin: 0 0 8px 0;
    color: #333;
}

.mention-count {
    margin: 4px 0;
    font-size: 14px;
    color: #555;
}

.mention-percentage {
    margin: 4px 0 0 0;
    font-size: 14px;
    color: #555;
    font-weight: 500;
}

/* Custom legend styles */
.custom-legend {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 15px;
    max-width: 300px;
    max-height: 400px;
    overflow-y: auto;
}

.legend-item {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 6px;
    transition: background-color 0.2s ease;
}

.legend-item-active {
    background-color: rgba(0, 0, 0, 0.05);
    font-weight: bold;
}

.legend-item-active .legend-text {
    font-weight: 600;
}

.legend-color {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 12px;
    flex-shrink: 0;
}

/* Special styling for the "Other" category in the legend */
.legend-color-other {
    background-image: repeating-linear-gradient(
        45deg,
        #E0E0E0,
        #E0E0E0 3px,
        #F5F5F5 3px,
        #F5F5F5 6px
    );
}

.legend-details {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.legend-text {
    font-size: 14px;
    color: #333;
    font-weight: 500;
}

.legend-percentage {
    font-size: 12px;
    color: #666;
    margin-top: 2px;
}

/* Improve the appearance of the time range selector */
.time-range-selector {
    display: flex;
    gap: 5px;
}

.time-range-selector button {
    border-radius: 4px;
    padding: 6px 12px;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    cursor: pointer;
    transition: all 0.2s ease;
    font-weight: 500;
}

.time-range-selector button.active {
    background-color: #1976d2;
    color: white;
    border-color: #1976d2;
}

.time-range-selector button:hover:not(.active) {
    background-color: #e0e0e0;
}

/* Fix for recharts rendering */
.recharts-wrapper {
    margin: 0 auto;
}

/* Accessibility focus styles */
.legend-item:focus-visible {
    outline: 2px solid #1976d2;
    outline-offset: 2px;
}

/* Responsive adjustments */
@media (max-width: 900px) {
    .chart-with-legend {
        flex-direction: column;
        align-items: center;
    }
    
    .pie-chart-container {
        margin-bottom: 20px;
        width: 100%;
        max-width: 400px;
    }
    
    .custom-legend {
        max-width: 100%;
        width: 100%;
    }
}

@media (max-width: 500px) {
    .pie-chart-container {
        height: 350px;
    }
    
    .controls {
        flex-direction: column;
        align-items: stretch;
    }
    
    .product-selector,
    .time-range-selector {
        width: 100%;
    }
} 