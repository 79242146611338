.company-search {
    width: 100%;
    position: relative;
}

.search-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
}

/* Enhanced search input styling */
.search-container .MuiTextField-root {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 12px;
    transition: all 0.2s ease;
    backdrop-filter: blur(10px);
}

.search-container .MuiTextField-root:hover {
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.search-container .MuiInputBase-root {
    padding: 8px 12px;
    border-radius: 12px;
}

/* Add proper padding for input icons */
.search-container .MuiInputAdornment-root {
    margin-right: 8px;
}

.search-container .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
    margin-right: 0;
    margin-left: 8px;
}

.search-container .MuiIconButton-root {
    padding: 8px;
    margin-right: 4px;
}

.search-container .MuiInputBase-input {
    font-size: 1.1rem;
    padding: 8px 4px;
}

.search-container .MuiInputBase-input::placeholder {
    color: #9e9e9e;
    opacity: 0.8;
}

/* Enhanced suggestions panel */
.search-suggestions {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.95);
    border: 1px solid rgba(224, 224, 224, 0.8);
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    margin-top: 4px;
    z-index: 999; /* Lower than bookmark menu */
    max-height: 300px;
    overflow-y: auto;
    backdrop-filter: blur(10px);
    transition: all 0.2s ease;
}

.suggestion-section {
    padding: 12px 0;
}

.suggestion-section:not(:last-child) {
    border-bottom: 1px solid rgba(224, 224, 224, 0.5);
}

.suggestion-section h3 {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    padding: 8px 16px;
    font-size: 0.875rem;
    color: #666;
    font-weight: 500;
    letter-spacing: 0.3px;
}

.suggestion-item {
    padding: 10px 16px;
    cursor: pointer;
    transition: all 0.15s ease;
    color: #424242;
    font-size: 0.95rem;
}

.suggestion-item:hover,
.suggestion-item:focus {
    background-color: rgba(25, 118, 210, 0.08);
    color: #1976d2;
    outline: none;
}

.search-error {
    margin-top: 12px;
    padding: 12px 16px;
    background-color: rgba(255, 235, 238, 0.9);
    color: #c62828;
    border-radius: 8px;
    font-size: 0.875rem;
    backdrop-filter: blur(8px);
    border: 1px solid rgba(198, 40, 40, 0.1);
}

.no-results-message {
    margin-top: 24px;
    text-align: center;
    color: #666;
    padding: 24px;
    background: rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    font-size: 0.95rem;
}

.search-results {
    margin-top: 24px;
}

/* Accessibility focus styles */
.suggestion-item:focus-visible {
    outline: 2px solid #1976d2;
    outline-offset: -2px;
    background-color: rgba(25, 118, 210, 0.08);
}

/* Loading state styles */
.MuiCircularProgress-root {
    color: #1976d2;
}

/* Scrollbar styling */
.search-suggestions::-webkit-scrollbar {
    width: 8px;
}

.search-suggestions::-webkit-scrollbar-track {
    background: transparent;
}

.search-suggestions::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
    .search-container {
        padding: 0 16px;
    }
    
    .search-suggestions {
        position: fixed;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        max-height: 60vh;
        border-radius: 16px 16px 0 0;
        box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.1);
        border: none;
        border-top: 1px solid rgba(224, 224, 224, 0.8);
    }

    .search-container .MuiTextField-root {
        background: rgba(255, 255, 255, 0.95);
    }
} 