.company-page-new {
    display: flex;
    flex-direction: column;
    padding-top: 90px;
    padding-left: 2rem;
    padding-right: 2rem;
    /* padding-bottom: 1rem; */
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
}

.company-page-section-title {
    padding: 0;
    margin: 0;
    margin-bottom: 0px;
    font-size: 1.25rem;
    font-weight: 500;
    color: #333;
}

.company-header {
    min-height: 75px;
    position: fixed;
    top: 64px;
    left: 2rem;
    right: 2rem;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1.5rem;
    background-color: white;
    border-radius: 10px;
    border: 1.5px solid #cacaca;
    margin-bottom: 1rem;
    background: var(--card-bg);
    border-radius: var(--card-radius);
    /* border: var(--card-border); */
    /* box-shadow: var(--card-shadow); */
}

.header-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.company-info {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0;
    padding: 0;
}

.company-info h1 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
}

.company-metadata {
    display: flex;
    gap: 1rem;
    color: #666;
    font-size: 0.9rem;
    /* margin-top: 4px; */
}

.company-content {
    display: flex;
    position: relative;
    height: 100%;
    margin-top: 3.0rem;
}

.company-sidebar {
    position: fixed;
    top: 160px;
    left: 2rem;
    width: 210px;
    height: calc(100vh - 185px);
    overflow-y: auto;
    margin-top: 0px;
    outline: none;
    box-sizing: border-box;
}

.sidebar-section {
    margin-bottom: 1rem;
}

.sidebar-section > .sidebar-item {
    padding: 0.25rem 1rem;
    padding-right: 0rem;
    cursor: default;
    position: relative;
}

.sidebar-section > .sidebar-item.main-section {
    color: #9E9E9E;
    font-weight: 600;
    padding: 0.15rem 1rem;
    padding-right: 0rem;
    cursor: default;
    font-size: 14px;
    position: relative;
}

.sidebar-subsections .sidebar-item {
    padding: 0.25rem 2rem;
    padding-right: 0rem;
    cursor: pointer;
    color: #848c90;
    transition: background-color 0.2s;
    position: relative;
    font-size: 12px;
    font-weight: 600;
}

.sidebar-subsections .sidebar-item:hover {
    background-color: #EBEBEB;
    color: #848c90;
}

.sidebar-subsections .sidebar-item.active {
    background-color: #e3f2fd;
    color: #1976d2;
    font-weight: 600;
}

.sidebar-subsections .sidebar-item.active::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: #1976d2;
}

.sidebar-subsections {
    margin-left: 0;
}

.company-main-content {
    margin-left: 200px;
    width: calc(100% - 200px);
    padding: 2rem;
    padding-top: 0px;
    overflow-y: auto;
    margin-top: 0px;
    height: calc(100vh - 105px);
    position: relative;
    box-sizing: border-box;
}

.watchlist-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid #1976d2;
    border-radius: 4px;
    background-color: white;
    color: #1976d2;
    cursor: pointer;
    transition: all 0.2s;
}

.watchlist-button:hover {
    background-color: #f5f5f5;
}

.watchlist-button.on-watchlist {
    background-color: #1976d2;
    color: white;
}

.company-overview {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.company-description {
    background: var(--card-bg);
    border-radius: var(--card-radius);
    border: var(--card-border);
    box-shadow: var(--card-shadow);
    padding: var(--card-padding-md);
}

.stock-performance {
    @extend .card;
    @extend .card-md;
}

.progress-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.section-title {
    font-size: 1.25rem;
    font-weight: 500;
    color: #333;
    margin-bottom: 1.5rem;
}


.sidebar-section > .sidebar-item.main-section.clickable {
    color: #4F4F4F;
    cursor: pointer;
    transition: color 0.2s, background-color 0.2s;
}

.sidebar-section > .sidebar-item.main-section.clickable:hover {
    color: #1976d2;
    background-color: #EBEBEB;
}

.sidebar-section > .sidebar-item.main-section.clickable.active,
.sidebar-section > .sidebar-item.main-section.clickable.active:hover {
    background-color: #e3f2fd;
    color: #1976d2;
}

.sidebar-section > .sidebar-item.main-section.clickable.active::before,
.sidebar-section > .sidebar-item.main-section.clickable.active:hover::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: #1976d2;
}

.navbar-extension {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    height: 40px;
    background-color: #1042c1;
    z-index: 98;
}

.card-content {
    padding: 0px;
}

.average-ratings-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.average-ratings-table th,
.average-ratings-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.average-ratings-table th {
    background-color: #f5f5f5;
    font-weight: 600;
}

.average-ratings-table tr:hover {
    background-color: #f9f9f9;
}

.time-range-selector {
    margin-left: auto;
}

/* Style for MUI Toggle Buttons to match existing theme */
.MuiToggleButton-root {
    text-transform: none !important;
    font-size: 0.875rem !important;
}

.MuiToggleButton-root.Mui-selected {
    background-color: #1976d2 !important;
    color: white !important;
}

.MuiToggleButton-root:hover {
    background-color: rgba(25, 118, 210, 0.08) !important;
}

.card-md {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.no-data-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  padding: 2rem;
  text-align: center;
}

.no-data-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.no-data-message p {
  color: #666;
  margin-bottom: 1rem;
  text-align: left;
}

.success-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
  padding: 2rem;
}

.success-icon {
  font-size: 4rem !important;
  color: #1042c1;
  filter: drop-shadow(0 2px 4px rgba(0,0,0,0.1));
}

.success-message p {
  color: #333;
  font-size: 1.1rem;
  max-width: 400px;
  line-height: 1.5;
  margin: 0;
  font-weight: 500;
}

.no-data-container {
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-data-message, .success-message {
  width: 100%;
  max-width: 500px;
}

.products-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.product-card {
    border: 1px solid var(--border-color);
    border-radius: 8px;
    overflow: hidden;
}

.product-header {
    padding: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.2s ease;
}

.product-header:hover {
    background-color: var(--hover-color);
}

.product-name {
    margin: 0;
    font-size: 1.2rem;
    color: var(--text-primary);
}

.product-description {
    padding: 0 1rem 1rem;
    color: var(--text-secondary);
}

.product-charts {
    padding: 1rem;
    border-top: 1px solid var(--border-color);
}

.chart-container {
    margin-top: 1rem;
}

.chart-container h4 {
    margin-bottom: 1rem;
    color: var(--text-primary);
}

.stock-chart-header {
    padding: 16px 16px 0;
    display: flex;
    justify-content: flex-end;
}

.time-range-selector {
    display: flex;
    gap: 8px;
}

.time-range-toggle {
    background-color: #f5f5f5;
    border-radius: 4px;
}

.overview-grid {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 24px;
    width: 100%;
}

.overview-left, .overview-right {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.scuttlebutt-card .card-content {
    padding: 0 !important;
}

.scuttlebutt-date {
    font-size: 0.85rem;
    color: #666;
    margin-bottom: 8px;
}

.scuttlebutt-content {
    font-size: 0.95rem;
    line-height: 1.5;
    color: #333;
    margin-bottom: 8px;
}

.scuttlebutt-metadata {
    font-size: 0.85rem;
    color: #666;
}

.scuttlebutt-source {
    background-color: #f5f5f5;
    padding: 2px 8px;
    border-radius: 4px;
}

.company-title-row {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.company-logo-link {
    display: flex;
    align-items: center;
    transition: opacity 0.2s;
}

.company-logo-link:hover {
    opacity: 0.8;
}

.company-logo {
    max-height: 40px;
    max-width: 80px;

    /* padding-bottom: 8px; */
    /* width: auto; */
    /* object-fit: contain; */
    /* border-radius: 4px; */
}

.sidebar-item.focused {
    background-color: #f0f7ff;
    outline-offset: -2px;
}

.sidebar-item.focused:not(.active):focus-visible {
    color: #1976d2;
}

.sidebar-item:focus-visible {
    background-color: #f0f7ff;
    outline: none;
}

.sidebar-item:focus {
    outline: none;
}

/* Update hover styles to match focus styles */
.sidebar-subsections .sidebar-item:hover {
    background-color: #EBEBEB;
    color: #848c90;
}

.differentiators-content {
    padding: 1.5rem;
    max-width: 100%;
    margin: 0 auto;
}

.competitor-grid {
    display: grid;
    gap: 1.5rem;
}

.competitor-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    transition: box-shadow 0.2s ease;
}

.competitor-card:hover {
    box-shadow: 0 2px 6px rgba(0,0,0,0.15);
}

.competitor-header {
    padding: 1.5rem;
    cursor: pointer;
}

.competitor-title {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.competitor-name {
    font-size: 1.4rem;
    font-weight: 600;
    color: #2c3e50;
    margin-right: auto;
}

.competitor-content {
    display: grid;
    grid-template-columns: 400px 1fr;
    gap: 3rem;
    align-items: start;
}

.markdown-content {
    padding: 1rem 1.5rem 1.5rem;
    font-size: 14px;
    line-height: 1.6;
    color: #333;
    border-top: 1px solid #eee;
    animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.markdown-content h3 {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0 0 1.5rem;
    color: #2c3e50;
    padding-bottom: 1rem;
    border-bottom: 2px solid #e3e8ee;
}

.markdown-content h4 {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 2rem 0 1rem;
    color: #2c3e50;
}

.markdown-content p {
    margin: 1rem 0;
    color: #3c4858;
}

.markdown-content ul {
    margin: 1rem 0;
    padding-left: 1.5rem;
}

.markdown-content li {
    margin-bottom: 0.75rem;
    line-height: 1.6;
    color: #3c4858;
}

.markdown-content strong {
    color: #2c3e50;
    font-weight: 600;
}

.markdown-content em {
    color: #546e7a;
    font-style: italic;
}

.competitive-metrics {
    margin-top: 3rem;
    padding-top: 2rem;
    border-top: 1px solid #eee;
}

.competitive-metrics h4 {
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
    color: #2c3e50;
}

.metrics-grid {
    display: grid;
    gap: 1.5rem;
    margin-top: 1rem;
}

.competitive-summary-section {
    margin-bottom: 2rem;
    padding: 1rem;
    background: #f8f9fa;
    border-radius: 8px;
}

.competitive-summary-section h4 {
    margin-bottom: 1.5rem;
    color: #2c3e50;
    font-size: 1.2rem;
}

.competitor-summaries {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.competitor-analysis {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.competitor-section {
    border-bottom: 1px solid #eee;
    padding: 0.5rem 0;
}

.competitor-section:last-child {
    border-bottom: none;
}

.competitor-summary {
    padding: 1.5rem;
    cursor: pointer;
    position: relative;
    transition: background-color 0.2s ease;
}

.competitor-summary:hover {
    background-color: #f8f9fa;
}

.preference-stats {
    width: 100%;
}

.preference-bar {
    height: 16px;
    background: #e9ecef;
    border-radius: 8px;
    display: flex;
    overflow: hidden;
    margin-bottom: 0.75rem;
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.05);
    cursor: pointer;
    transition: opacity 0.2s ease;
}

.preference-segment.prefer-product:hover, .preference-segment.prefer-competitor:hover {
    opacity: 0.85;
}

.preference-segment.neutral:hover {
    background-color: #b8b8b8;  /* Gray */
}

.preference-segment {
    height: 100%;
    transition: all 0.2s ease;
}

.preference-segment.prefer-product {
    background-color: #4caf50;  /* Pale green */
}

.preference-segment.neutral {
    background-color: #b1b1b1;  /* Gray */
}

.preference-segment.prefer-competitor {
    background-color: #ef5350;  /* Pale red */
}

.competitor-card:hover {
    background-color: #f8f9fa;
}

.preference-labels {
    display: flex;
    justify-content: space-between;
    padding: 0 0.25rem;
    margin-bottom: 1.5rem;
}

.preference-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.preference-percentage {
    font-size: 1rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 0.25rem;
}

.preference-description {
    font-size: 0.85rem;
    font-weight: 400;
    font-style: italic;
    color: #64748b;
}

.head-to-head-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    color: #1976d2;
    text-decoration: none;
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
    transition: all 0.2s ease;
    background-color: transparent;
}

.head-to-head-link:hover {
    background-color: rgba(25, 118, 210, 0.08);
    color: #1565c0;
}

.churn-arrow {
    display: inline-flex;
    align-items: center;
    padding: 0.75rem 1.25rem;
    border-radius: 6px;
    color: white;
    font-size: 0.9rem;
    font-weight: 500;
    border: none;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: all 0.2s ease;
}

.churn-arrow:hover {
    transform: translateX(0) !important;
    filter: brightness(0.95);
}

.competitor-summary-text {
    font-size: 0.95rem;
    line-height: 1.6;
    color: #2c3e50;
    padding-right: 2rem;
}

.competitor-summary-text p {
    margin: 0 0 1.5rem 0;
    color: #3c4858;
}

.competitor-summary-text h4 {
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: #64748b;
    margin: 0 0 1rem 0;
}

.competitor-summary-text ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.competitor-summary-text li {
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 0.75rem;
    line-height: 1.5;
    color: #3c4858;
}

.competitor-summary-text li:last-child {
    margin-bottom: 0;
}

.competitor-summary-text li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: #1976d2;
    font-weight: bold;
    font-size: 1.2em;
    line-height: 1;
}

.mention-count {
    font-size: 0.85rem;
    color: #666;
    margin-top: 1rem;
}

@media (max-width: 1200px) {
    .competitor-content {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .preference-stats {
        max-width: 500px;
    }

    .competitor-summary-text {
        padding-right: 1.5rem;
    }

    .competitor-summary-text ul {
        grid-template-columns: 1fr;
    }
}

.markdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.markdown-header h3 {
    margin: 0;
    padding: 0;
    border: none;
}

.close-button {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: #64748b;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
}

.close-button:hover {
    background-color: #f1f5f9;
    color: #475569;
}

.close-button svg {
    font-size: 20px;
}

.market-overview-card {
    margin-bottom: 2rem;
    border: 1px solid #e3e8ee;
}

.market-overview-card .competitor-name {
    color: #1976d2;
}

.market-overview-card .preference-bar {
    height: 20px;  /* Slightly larger bar for the market overview */
}

.market-overview-card .preference-percentage {
    font-size: 1.1rem;  /* Slightly larger percentages */
}

.scuttlebutt-content-wrapper {
    max-width: 100%;
    overflow-x: hidden;
}

.content-section {
    height: 100%;
    padding-top: 1rem;
}

.scuttlebutt-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    border-bottom: 1px solid #eee;
}

.scuttlebutt-header h3 {
    margin: 0;
    font-size: 0.9rem;
    font-weight: 500;
    color: #666;
}

.view-all-button {
    display: flex;
    align-items: center;
    gap: 4px;
    background: none;
    border: none;
    color: #1042c1;
    font-size: 0.85rem;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
    transition: background-color 0.2s;
}

.view-all-button:hover {
    background-color: #f0f7ff;
}

.view-all-button svg {
    font-size: 16px;
}

.view-more-container {
    display: flex;
    justify-content: center;
    padding: 16px;
    border-top: 1px solid #eee;
}

.view-more-button {
    background: none;
    border: none;
    color: #1042c1;
    font-size: 0.9rem;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.view-more-button:hover {
    background-color: #f0f7ff;
}

.card-header-with-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.search-container {
    padding: 20px;
}

.search-button-container {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
}

.search-button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 10px 24px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    transition: background-color 0.2s;
}

.search-button:hover:not(:disabled) {
    background-color: var(--primary-color-dark);
}

.search-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.search-results {
    margin-top: 24px;
}

.no-results-message {
    text-align: center;
    color: #666;
    padding: 24px;
    font-size: 16px;
}

/* Ensure the search input has consistent styling */
.search-container .MuiTextField-root {
    background-color: white;
}

.search-container .MuiInputBase-root {
    padding: 8px 12px;
}

.search-container .MuiInputAdornment-root .MuiSvgIcon-root {
    color: #666;
}
  

/* Custom Toggle Styling */
.control-group {
    display: flex;
    align-items: center;
    gap: 16px;
}

.custom-toggle {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    user-select: none;
}

.custom-toggle.small {
    transform: scale(0.85);
    transform-origin: left center;
}

.toggle-label {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
}

.toggle-label input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e0e0e0;
    transition: .3s;
    border-radius: 24px;
}

.toggle-slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .3s;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.toggle-label input:checked + .toggle-slider {
    background-color: #1976d2;
}

.toggle-label input:checked + .toggle-slider:before {
    transform: translateX(20px);
}

.toggle-text {
    font-size: 14px;
    font-weight: 500;
    color: #4a4a4a;
    white-space: nowrap;
}

/* Hover effects */
.toggle-label:hover .toggle-slider {
    background-color: #d0d0d0;
}

.toggle-label:hover input:checked + .toggle-slider {
    background-color: #1565c0;
}

/* Focus styles for accessibility */
.toggle-label input:focus-visible + .toggle-slider {
    box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
}

.page-header {
    margin-bottom: 32px;
    max-width: 800px;  /* Match post display width */
}

.page-header .header-content {
    width: 100%;
}

.page-header .header-top {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 8px;
}

.page-header .page-title {
    font-size: 20px;
    font-weight: 500;
    color: #1a1a1a;
    margin: 0;
    line-height: 1.4;
}

.page-header .subtitle {
    font-size: 13px;
    color: #666;
    margin-left: 48px;  /* Align with title, accounting for back button width */
}

.company-logo-svg {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.company-logo-svg svg {
    width: 100%;
    height: 100%;
}

/* For the search preview specifically */
.search-preview-item .company-logo-svg {
    width: 20px;
    height: 20px;
}

/* For the company header specifically */
.company-logo-link .company-logo-svg {
    width: 40px;
    height: 40px;
}

.custom-assessment-display {
    padding: 20px;
}

.assessment-description {
    margin-bottom: 20px;
    font-size: 1.1em;
    color: #666;
}

.assessment-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
}

.stat-card {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.stat-card h3 {
    margin: 0 0 10px 0;
    font-size: 1em;
    color: #666;
}

.stat-card p {
    margin: 0;
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
}

.rating-distribution {
    margin-bottom: 30px;
}

.distribution-bars {
    display: flex;
    height: 30px;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 10px;
}

.distribution-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 0.9em;
    text-shadow: 0 1px 2px rgba(0,0,0,0.2);
}

.distribution-bar.positive {
    background-color: #4caf50;
}

.distribution-bar.neutral {
    background-color: #ff9800;
}

.distribution-bar.negative {
    background-color: #f44336;
}

.assessment-chart {
    margin-bottom: 30px;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.assessment-details {
    margin-top: 30px;
}

.assessment-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.assessment-item {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.assessment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.assessment-date {
    color: #666;
    font-size: 0.9em;
}

.assessment-rating {
    font-weight: bold;
    padding: 4px 8px;
    border-radius: 4px;
}

.assessment-rating.positive {
    background-color: #e8f5e9;
    color: #2e7d32;
}

.assessment-rating.neutral {
    background-color: #fff3e0;
    color: #ef6c00;
}

.assessment-rating.negative {
    background-color: #ffebee;
    color: #c62828;
}

.assessment-explanation {
    margin: 10px 0;
    line-height: 1.5;
}

.assessment-comparator {
    color: #666;
    font-size: 0.9em;
    margin: 5px 0;
}

.assessment-metrics {
    display: flex;
    gap: 20px;
    color: #666;
    font-size: 0.9em;
    margin-top: 10px;
}