.preference-bar-container {
    width: 100%;
}

.preference-bar {
    height: 24px;
    display: flex;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 8px;
}

.preference-segment {
    height: 100%;
    transition: width 0.3s ease;
}

.preference-segment.promoters {
    background-color: #4caf50;
}

.preference-segment.neutrals {
    background-color: #9e9e9e;
}

.preference-segment.detractors {
    background-color: #f44336;
}

.percentage-labels {
    display: flex;
    justify-content: space-between;
    font-size: 0.85em;
    color: #666;
}

.percentage-labels span {
    flex: 1;
    text-align: center;
}

.percentage-labels span.promoters {
    color: #4caf50;
}

.percentage-labels span.neutrals {
    color: #666;
}

.percentage-labels span.detractors {
    color: #f44336;
} 