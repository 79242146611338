.company-chat {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    margin: 0;
    overflow: hidden;
    height: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 1.5rem;
    margin: 0;
    background: #fff;
}

.empty-chat {
    text-align: center;
    padding: 3rem 2rem;
    max-width: 600px;
    margin: 0 auto;
}

.empty-chat h3 {
    margin-bottom: 1.5rem;
    color: #1a1a1a;
    font-size: 1.5rem;
    font-weight: 500;
}

.empty-chat p {
    color: #666;
    margin-bottom: 1.5rem;
    font-size: 0.95rem;
}

.empty-chat ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin: 0;
}

.empty-chat li {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #1976d2;
    cursor: pointer;
    transition: all 0.2s ease;
    background: #f8f9fa;
    border-radius: 8px;
    font-size: 0.95rem;
    border: 1px solid #e0e0e0;
}

.empty-chat li:hover {
    background: #f0f7ff;
    border-color: #1976d2;
    transform: translateY(-1px);
}

.message {
    margin-bottom: 16px;
    padding: 0;
    display: flex;
    width: 100%;
}

.message:last-child {
    margin-bottom: 0;
}

.message.user {
    justify-content: flex-end;
}

.message.assistant {
    justify-content: flex-start;
}

.message.error {
    margin-right: auto;
    color: #d32f2f;
}

.message-content {
    padding: 12px 16px;
    border-radius: 12px;
    line-height: 1.5;
    margin: 0;
    max-width: min(640px, 85%);
}

.message.user .message-content {
    background-color: #1976d2;
    color: white;
}

.message.assistant .message-content {
    background-color: #f5f5f5;
    color: #333;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    max-width: min(640px, 85%);
}

.message.error .message-content {
    background-color: #ffebee;
    color: #d32f2f;
}

.message.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.chat-input-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 12px 16px;
    background-color: #fff;
    border-top: 1px solid #e0e0e0;
    margin: 0;
}

.chat-input-form input {
    flex: 1;
    height: 40px;
    padding: 0 16px;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    font-size: 14px;
    background: #f5f5f5;
    margin: 0;
    height: 40px;

}

.chat-input-form input:focus {
    outline: none;
    border-color: #1976d2;
    background: #fff;
}

.chat-input-form button {
    width: 40px;
    height: 40px;
    min-width: 40px;
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 50%;
    background-color: #1976d2;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s;
    flex-shrink: 0;
}

.chat-input-form button:hover {
    background-color: #1565c0;
}

.chat-input-form button:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
}

/* Message content styling */
.answer-text {
    font-size: 14px;
    line-height: 1.6;
    margin: 0;
    padding: 0;
}

.answer-paragraph {
    margin: 0 0 12px 0;
    padding: 0;
}

.answer-paragraph:last-child {
    margin-bottom: 0;
}

.source-references {
    display: inline;
    margin-left: 2px;
}

.source-reference {
    color: #1976d2;
    text-decoration: none;
    transition: color 0.2s;
    margin: 0;
    padding: 0;
}

.source-reference:hover {
    color: #1565c0;
}

.source-reference sup {
    font-size: 11px;
    line-height: 0;
    border-bottom: 1px solid transparent;
    transition: border-color 0.2s;
}

.source-reference:hover sup {
    border-bottom-color: #1565c0;
}

/* Source reference styling */
.source-reference {
    color: #1976d2;
    cursor: pointer;
    margin-left: 2px;
    font-weight: 500;
}

/* Sources section styling */
.sources-section {
    margin-top: 12px;
    border-radius: 8px;
    overflow: hidden;
}

.sources-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    background-color: #f8f9fa;
    cursor: pointer;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    transition: background-color 0.2s;
}

.sources-header:hover {
    background-color: #f0f0f0;
}

.sources-header h4 {
    margin: 0;
    font-size: 14px;
    color: #666;
    font-weight: 500;
}

.sources-content {
    margin-top: 8px;
}

.source-item {
    display: flex;
    margin-bottom: 12px;
    padding: 12px;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
}

.source-number {
    flex-shrink: 0;
    width: 30px;
    color: #1976d2;
    font-weight: 500;
}

.loading-source {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
}

/* Nested post styling */
.source-item .post {
    flex-grow: 1;
    margin: 0;
    padding: 0;
    border: none;
    box-shadow: none;
}

.message.assistant .message-content {
    background-color: #f5f5f5;
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.message.assistant .answer-item {
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 16px;
    border: 1px solid #e0e0e0;
} 