/* Base styles */
.key-people {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
  width: 100%;
}

/* Header styles */
.page-header {
  margin-bottom: 1.5rem;
}

.page-header h1 {
  font-weight: 600;
  color: #000;
  margin-bottom: 0.5rem;
  font-size: 28px;
}

.page-header p {
  color: #536471;
  font-size: 15px;
}

/* Filters section */
.filters {
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02);
}

.filters-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
}

@media (min-width: 768px) {
  .filters-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.filter-item {
  width: 100%;
}

/* Input styles */
.search-input,
.select-input {
  width: 100%;
  padding: 12px 16px;
  font-size: 14px;
  background-color: #f8f9fa;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  transition: border-color 0.2s ease;
}

.search-input:hover,
.select-input:hover {
  border-color: rgba(0, 0, 0, 0.15);
}

.search-input:focus,
.select-input:focus {
  outline: none;
  border-color: #0066cc;
}

/* Results grid */
.people-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .people-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .people-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Person card */
.person-card {
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02);
  padding: 20px;
  height: 300px; /* Fixed height */
  width: 100%; /* Full width of the grid column */
  transition: box-shadow 0.15s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.person-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.person-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
}

.person-info {
  flex: 1;
  padding-right: 1rem;
}

.person-info h2 {
  font-weight: 600;
  color: #000;
  font-size: 18px;
  margin: 0 0 4px 0;
  line-height: 1.3;
}

.twitter-handle {
  color: #536471;
  font-size: 15px;
}

.external-link {
  background-color: #f7f7f7;
  width: 32px;
  height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
}

.external-link:hover {
  background-color: #f0f0f0;
}

.external-icon {
  fill: currentColor;
}

.person-description {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 16px;
}

.person-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
}

/* Chips */
.score-chip {
  font-size: 14px;
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 14px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  cursor: help;
  position: relative;
}

.score-chip:hover .importance-explanation {
  display: block;
}

.score-high {
  background-color: #4caf50;
  color: #fff;
}

.score-medium {
  background-color: #ffd700;
  color: #000;
}

.score-low {
  background-color: #ef5350;
  color: #fff;
}

.org-chip {
  font-size: 14px;
  padding: 4px 12px;
  border-radius: 14px;
  height: 28px;
  background-color: #f7f7f7;
  color: rgba(0, 0, 0, 0.8);
  display: inline-flex;
  align-items: center;
}

.employment-chip {
  font-size: 14px;
  padding: 4px 12px;
  border-radius: 14px;
  height: 28px;
  display: inline-flex;
  align-items: center;
}

.employment-chip.current {
  background-color: #e8f4ff;
  color: #0066cc;
}

.employment-chip.former {
  background-color: #f7f7f7;
  color: rgba(0, 0, 0, 0.8);
}

.importance-explanation {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 8px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 12px;
  width: 280px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1;
  color: #536471;
  font-size: 14px;
  line-height: 1.5;
  font-style: italic;
}

/* Loading and error states */
.loading,
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
}

.loading {
  color: #0066cc;
  font-size: 16px;
}

.error {
  color: #ef5350;
  font-size: 16px;
  font-weight: 500;
} 