.price-comparison-display {
    padding: 20px;
}

.price-histogram-section {
    background: #fff;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
}

.price-histogram-section h2 {
    margin: 0 0 24px 0;
    font-size: 24px;
    color: #333;
}

.price-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 24px;
    margin-bottom: 32px;
    background: #f8f9fa;
    padding: 20px;
    border-radius: 6px;
}

.stat {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.stat .label {
    font-size: 14px;
    color: #666;
    font-weight: 500;
}

.stat .value {
    font-size: 24px;
    font-weight: 600;
    color: #333;
}

.histogram-container {
    background: #fff;
    padding: 16px;
    border-radius: 6px;
    margin-bottom: 16px;
}

/* Custom tooltip styles */
.custom-tooltip {
    background: #fff;
    border: 1px solid #ddd;
    padding: 12px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-tooltip .price-range {
    font-weight: 600;
    margin: 0 0 4px 0;
    color: #333;
}

.custom-tooltip .post-count {
    margin: 0;
    color: #666;
}

.filter-notice {
    background: #e3f2fd;
    padding: 12px 16px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    font-size: 14px;
    color: #1976d2;
}

.filter-notice strong {
    font-weight: 600;
}

.clear-filter {
    background: #1976d2;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;
    transition: background-color 0.2s;
}

.clear-filter:hover {
    background: #1565c0;
}

/* Bar hover effect */
.recharts-bar-rectangle:hover {
    opacity: 0.8;
    transition: opacity 0.2s;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .price-stats {
        grid-template-columns: 1fr;
        gap: 16px;
    }

    .stat .value {
        font-size: 20px;
    }

    .filter-notice {
        flex-direction: column;
        gap: 12px;
        text-align: center;
    }
}

.posts-section {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.posts-section h2 {
    margin: 0 0 20px 0;
    color: #333;
    font-size: 1.5em;
}

/* Override some FilteredPostsDisplay styles */
.posts-section .filtered-posts-section {
    box-shadow: none;
    padding: 0;
}

.posts-section .filtered-posts-header {
    margin-bottom: 20px;
}

/* Ensure the chart is responsive */
@media (max-width: 768px) {
    .histogram-container {
        height: 250px;
    }
    
    .price-histogram-section,
    .posts-section {
        padding: 15px;
    }

    .price-stats {
        grid-template-columns: repeat(2, 1fr);
        padding: 15px;
    }
}

.value-container {
    display: flex;
    align-items: baseline;
    gap: 4px;
}

.significance-indicator {
    font-size: 20px;
    font-weight: bold;
    color: #1976d2;
    cursor: help;
}

.significance-note {
    font-size: 12px;
    color: #666;
    margin-top: 4px;
    font-style: italic;
} 