/* Variables */
:root {
  --shadow-default: 0 4px 20px rgba(0, 0, 0, 0.15);
  --shadow-light: 0 2px 4px rgba(0, 0, 0, 0.1);
  --border-color: #e0e0e0;
  --bg-hover: #e6e7e7;
  --text-primary: #2d3436;
  --text-secondary: #636e72;
  --primary-blue: #1976d2;
  --transition-default: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  --animation-duration: 200ms;
}

/* Search Preview Container */
.search-preview-container {
  position: fixed;
  z-index: 10000;
  background: white;
  border-radius: 12px;
  box-shadow: var(--shadow-default);
  border: 1px solid var(--border-color);
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  margin-top: 8px;
  padding: 8px 0;
  opacity: 0;
  transform: translateY(-10px) scale(0.98);
  transition: 
    opacity var(--animation-duration) cubic-bezier(0.4, 0, 0.2, 1),
    transform var(--animation-duration) cubic-bezier(0.4, 0, 0.2, 1);
  min-height: 50px;
  will-change: transform, opacity;
  pointer-events: none;
}

.search-preview-container.absolute {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 4px;
  border-radius: 4px;
  box-shadow: var(--shadow-light);
}

.search-preview-container.visible {
  opacity: 1;
  transform: translateY(0) scale(1);
  pointer-events: auto;
}

.search-preview-wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1000;
}

/* Search Box */
.search-box {
  position: relative;
  background-color: #fbfbfb;
  border: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  z-index: 1;
}

/* Search Preview Items */
.search-preview-item {
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.15s ease;
  display: flex;
  align-items: center;
  gap: 12px;
  opacity: 0;
  transform: translateY(-5px);
  animation: fadeSlideIn 0.2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.search-preview-item:hover,
.search-preview-item.selected {
  background-color: var(--bg-hover);
}

/* Company Icon */
.search-preview-item .company-icon {
  width: 28px;
  height: 28px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-hover);
  border: 1px solid #eee;
  padding: 4px;
}

.search-preview-item .company-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Company Info */
.search-preview-item .company-info {
  flex: 1;
}

.search-preview-item .company-name {
  font-weight: 500;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  gap: 8px;
}

.company-ticker-badge {
  font-size: 12px;
  font-weight: 600;
  color: var(--text-secondary);
  background-color: #f1f2f6;
  padding: 2px 6px;
  border-radius: 4px;
}

/* Header Search */
.header-search-container {
  position: relative;
  width: 200px;
  transition: width var(--animation-duration) cubic-bezier(0.4, 0, 0.2, 1);
}

.header-search-container.focused {
  width: 400px;
}

.header-search-input {
  width: 100%;
  height: 40px;
  padding: 8px 16px 8px 40px;
  border: 1px solid var(--border-color);
  border-radius: 2px;
  font-size: 14px;
  outline: none;
  transition: var(--transition-default);
  background-color: transparent;
  color: #fff;
}

.header-search-input::placeholder {
  color: rgba(255, 255, 255, 1);
  transition: color var(--animation-duration) ease;
}

.header-search-input:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.header-search-input:focus {
  background-color: #ffffff;
  border-color: var(--primary-blue);
  box-shadow: 0 0 0 3px rgba(25, 118, 210, 0.08);
  color: #7c7c7c;
}

.header-search-input:focus::placeholder {
  color: #7c7c7c;
}

.search-icon-wrapper {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.7);
  pointer-events: none;
  z-index: 1;
  transition: color var(--animation-duration) ease;
}

.search-icon-wrapper.focused {
  color: #1042c1;
}

/* Animations */
@keyframes fadeSlideIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.search-preview-item:nth-child(1) { animation-delay: 0.05s; }
.search-preview-item:nth-child(2) { animation-delay: 0.1s; }
.search-preview-item:nth-child(3) { animation-delay: 0.15s; }
.search-preview-item:nth-child(4) { animation-delay: 0.2s; }
.search-preview-item:nth-child(5) { animation-delay: 0.25s; }

/* Loading State */
.search-preview-item.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  color: var(--text-secondary);
  animation: pulse 1.5s ease-in-out infinite;
  background: none;
  cursor: default;
  opacity: 1;
  transform: none;
  padding: 0 16px;
  font-size: 0.95rem;
}

.search-preview-item.loading:hover {
  background: none;
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

/* Ensure preview container is compact when only showing loading state */
.search-preview-container:has(.loading) {
  max-height: 48px;
  overflow: hidden;
}

/* Media Queries */
@media (max-width: 768px) {
  .header-search-container {
    width: 160px;
  }
  
  .header-search-container.focused {
    width: 280px;
  }
  
  .search-preview-container {
    width: 100%;
  }
} 