.nps-comparison-chart {
    padding: 20px;
    background: white;
    border-radius: 8px;
}

.nps-comparison-chart .chart-controls {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    align-items: center;
}

.nps-comparison-chart .chart-wrapper {
    margin: 20px 0;
    height: 400px;
}

.nps-comparison-chart .comparison-section {
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid #eee;
}

.nps-comparison-chart .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.nps-comparison-chart .section-header h3 {
    margin: 0;
    color: #333;
}

.nps-comparison-chart .comparison-chart-wrapper {
    margin: 20px 0;
    height: 300px;
}

.nps-comparison-chart .nps-breakdown-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 30px;
}

.nps-comparison-chart .nps-breakdown-card {
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nps-comparison-chart .nps-breakdown-card h4 {
    margin: 0 0 15px 0;
    color: #333;
}

.nps-comparison-chart .nps-breakdown-card.main-product {
    background-color: rgba(47, 71, 184, 0.1);
    border: 1px solid rgba(47, 71, 184, 0.2);
}

.nps-comparison-chart .nps-breakdown-card.competitor {
    background-color: rgba(75, 192, 192, 0.1);
    border: 1px solid rgba(75, 192, 192, 0.2);
}

.nps-comparison-chart .preference-bar {
    height: 24px;
    display: flex;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 15px;
}

.nps-comparison-chart .preference-segment {
    height: 100%;
    transition: width 0.3s ease;
}

.nps-comparison-chart .preference-segment.promoters {
    background-color: #4caf50;
}

.nps-comparison-chart .preference-segment.neutrals {
    background-color: #9e9e9e;
}

.nps-comparison-chart .preference-segment.detractors {
    background-color: #f44336;
}

.nps-comparison-chart .breakdown-stats {
    margin-top: 15px;
}

.nps-comparison-chart .breakdown-stats p {
    margin: 5px 0;
    color: #666;
    font-size: 0.9em;
}

.nps-comparison-chart .summary-stats {
    margin-top: 30px;
}

.nps-comparison-chart .summary-stats h3 {
    margin-bottom: 20px;
    color: #333;
}

.nps-comparison-chart .stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.nps-comparison-chart .stat-card {
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nps-comparison-chart .stat-card h4 {
    margin-bottom: 10px;
    color: #333;
}

.nps-comparison-chart .stat-card p {
    margin: 5px 0;
    color: #666;
}

.nps-comparison-chart .stat-card.main-product {
    background-color: rgba(47, 71, 184, 0.1);
    border: 1px solid rgba(47, 71, 184, 0.2);
}

.nps-comparison-chart .stat-card.competitor {
    background-color: rgba(75, 192, 192, 0.1);
    border: 1px solid rgba(75, 192, 192, 0.2);
} 